import { Size } from "./size";

export class Product {
  public id: string;
  public articleId?: string;
  public name: string;
  public newPrice: number;
  public description: string;
  public sizes: Size[];
  public chosenSize: string;
  public cartCount?: number = 1;

  constructor() {}
}
