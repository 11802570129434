import { Amount } from "./amount";

export class Payment {
  public amount: Amount = new Amount();
  public description: string;
  public redirectUrl: string;
  public webhookUrl: string;
  public locale: string;

  constructor() {}
}
