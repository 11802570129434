<div id="error">
    <h1>Oeps!</h1>
<p class="zoom-area">Zo te zien is er iets misgegaan of ben je verdwaald.</p>
<div class="link-container">
    <a [routerLink]="['/']" class="more-link">Terug naar inschrijven</a>
  </div>
<section class="error-container">
  <span class="four"><span class="screen-reader-text">4</span></span>
  <br fxHide.gt-sm/>
  <span class="zero"><span class="screen-reader-text">0</span></span>
  <br fxHide.gt-sm/>
  <span class="four"><span class="screen-reader-text">4</span></span>
</section>

</div>