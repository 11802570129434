<div *ngIf="!this.isActive" fxLayout="column" fxLayoutAlign="start center">
  <div class="background" fxLayoutAlign="center center">
    <!-- <h1>Lidmaatschap TC Katteberg</h1> -->
    <img src="../../assets/img/logo_white.svg"/>
  </div>

  <div class="info" fxLayout="column" fxLayoutAlign="start center">
    <h2>LIDMAATSCHAP ZOMERTENNIS EN PADEL</h2>
    <p>Inschrijvingen worden binnenkort terug opengesteld.</p>
    
  </div>
</div>

<div class="container" fxLayout="column" fxLayoutAlign="start center" *ngIf="this.isActive">

    <div class="background" fxLayoutAlign="center center">
      <!-- <h1>Lidmaatschap TC Katteberg</h1> -->
      <img src="../../assets/img/logo_white.svg"/>
    </div>
  
    <div class="info">
      <h2>LIDMAATSCHAP ZOMERTENNIS EN PADEL</h2>
      <p>Hoe kan ik lid worden of mijn lidmaatschap verlengen?</p>
      <p>Hieronder kan je gemakkelijk online lid worden en meteen online betalen. Opteer voor jouw beste tennis en/of padel formule!</p>
      <p>Goed om te weten:</p>
      <ul>
        <li>De tarieven verschillen naargelang je leeftijdscategorie</li>
        <li>Je kan je inschrijven voor een lidmaadschap tennis en padel.</li>
        <li>De inschrijving van het lidmaatschap is individueel op naam, met uitzondering van een familie lidmaatschap</li>
        <li>Je lidmaatschap zal pas geactiveerd worden na de ontvangst van je betaling</li>
      </ul>
    </div>
    
    <div class="form-container">
      <form #subscriptionForm="ngForm" fxLayout="column">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px">
          <mat-form-field fxFlex="50" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Voornaam</mat-label>
            <input matInput required name="firstname" [(ngModel)]="subscription.firstname">
          </mat-form-field>
  
          <mat-form-field fxFlex="50" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Naam</mat-label>
            <input matInput required name="lastname" [(ngModel)]="subscription.lastname">
          </mat-form-field>
        </div>
  
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Rijksregisternummer</mat-label>
          <input matInput required name="registernumber" [(ngModel)]="subscription.registernumber">
        </mat-form-field>
        
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>E-mailadres</mat-label>
          <input matInput required name="emailaddress" type="email" #emailaddress="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="subscription.emailaddress">
          <mat-error *ngIf="emailaddress.errors?.pattern">
            Gelieve een geldig e-mailadres in te geven
          </mat-error>
        </mat-form-field>

        
  
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Telefoonnummer</mat-label>
          <input matInput required name="phonenumber" [(ngModel)]="subscription.phonenumber">
        </mat-form-field>
  
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px">
          <mat-form-field fxFlex="70" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Straatnaam</mat-label>
            <input matInput required name="street" [(ngModel)]="subscription.street">
          </mat-form-field>
  
          <mat-form-field fxFlex="30" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Huisnummer</mat-label>
            <input matInput required name="housenumber" [(ngModel)]="subscription.housenumber">
          </mat-form-field>
        </div>
  
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px">
          <mat-form-field fxFlex="70" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Stad</mat-label>
            <input matInput required name="city" [(ngModel)]="subscription.city">
          </mat-form-field>
  
          <mat-form-field fxFlex="30" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Postcode</mat-label>
            <input matInput required name="zipcode" [(ngModel)]="subscription.zipcode">
          </mat-form-field>
        </div>
        
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px">
          <mat-form-field fxFlex="50" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Geboortedatum</mat-label>
            <input matInput required name="birthdate" placeholder="bijv. 01/01/2022" [(ngModel)]="subscription.birthdate">
          </mat-form-field>
  
          <mat-form-field fxFlex="50" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Geslacht</mat-label>
            <mat-select name="gender" [(ngModel)]="subscription.gender" required>
              <mat-option value="Man">
                Man
              </mat-option>
              <mat-option value="Vrouw">
                Vrouw
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h3>Welk type lidmaatschap wens je aan te schaffen of te verlengen?</h3>

        <div fxLayout="column">
          <mat-form-field fxFlex="100" fxFlex.lt-md="100" appearance="outline">
            <mat-label>Type lidmaatschap</mat-label>
            <mat-select required name="type" [(ngModel)]="this.type" (selectionChange)="changeFormulaType()">
              <mat-option value="Tennis">
                Tennis
              </mat-option>
              <mat-option value="Padel">
                Padel
              </mat-option>
              <mat-option value="Combi">
                Combi (Tennis + Padel)
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <p>*Voor een lidmaatschap padel gelieve contact op te nemen met Karel Demuynck – Sportief manager telefonisch op 0488 / 38 63 07 of via mail op karel@tckatteberg.be</p> -->
        </div>
       
        <h3>Selecteer hieronder jouw lidmaatschap formule*</h3>

        <section *ngIf="this.type == 'Tennis'">
          <p>Zomer lidmaatschap Tennis (1 april – 31 oktober, afhankelijk van het weer)</p>
  
          <mat-radio-group required fxLayout="column" fxLayoutGap="15px" name="formulaTennis" [(ngModel)]="subscription.formulaTennis">
             <mat-radio-button *ngFor="let option of this.tennisProducts[0].sizes; let i = index" value="{{i + 1}}" (change)="radioChangeTennis($event, option)">{{ option.name}} <span *ngIf="i == 5"><br fxHide.gt-sm/> (voor gezinnen vanaf 3 of meer personen) <br fxHide.gt-sm/></span> - €{{ option.newPrice }}</mat-radio-button>
           </mat-radio-group> 
   
           <br/>
   
           <section *ngFor="let member of this.subscription.familymembers; let i = index">
            <div fxLayout="column" *ngIf="subscription.formulaTennis == '6'" style="border-bottom: 2px solid #eb5f44;">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <p>Familielid {{i+2}}:</p>
                <button *ngIf="i >= 2" color="secondary" mat-mini-fab style="width: 40px;" (click)="removeFamilymember(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Voornaam</mat-label>
                  <input matInput required name="firstname {{i}}" [(ngModel)]="member.firstname">
                </mat-form-field>
        
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Naam</mat-label>
                  <input matInput required name="lastname {{i}}" [(ngModel)]="member.lastname">
                </mat-form-field>

                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Geboortedatum</mat-label>
                  <input matInput required name="birthdate {{i}}" [(ngModel)]="member.birthdate">
                </mat-form-field>
              </div>

              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Rijksregisternummer</mat-label>
                  <input matInput required name="registernumber {{i}}" [(ngModel)]="member.registernumber">
                </mat-form-field>
        
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>E-mailadres</mat-label>
                  <input matInput required name="emailaddress {{i}}" [(ngModel)]="member.emailaddress">
                </mat-form-field>

                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Geslacht</mat-label>
                  <mat-select name="gender {{i}}" [(ngModel)]="member.gender" required>
                    <mat-option value="Man">
                      Man
                    </mat-option>
                    <mat-option value="Vrouw">
                      Vrouw
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              
              </div>
            </div>
            <br/>
           </section>
           <div fxFill fxLayout="row" fxLayoutAlign="space-around center" *ngIf="subscription.formulaTennis == '6'">
            <button mat-raised-button color="primary" (click)="addFamilymember()">+ Nog een familielid toevoegen</button>
           </div>
        </section>

        <section *ngIf="this.type == 'Padel'">
          <p>Padel lidmaatschap</p>
  
          <mat-radio-group required fxLayout="column" fxLayoutGap="15px" name="formulaPadel" [(ngModel)]="subscription.formulaPadel">
            <mat-radio-button *ngFor="let option of this.padelProducts[0].sizes; let i = index" value="{{i + 1}}" (change)="radioChangePadel($event, option)">{{ option.name}} <span *ngIf="i == 5"><br fxHide.gt-sm/> (voor gezinnen vanaf 3 of meer personen) <br fxHide.gt-sm/></span> - €{{ option.newPrice }}</mat-radio-button>
           </mat-radio-group> 
     
           <br/>
     
           <section *ngFor="let member of this.subscription.familymembers; let i = index">
            <div fxLayout="column" *ngIf="subscription.formulaPadel == '6'" style="border-bottom: 2px solid #eb5f44;">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <p>Familielid {{i+2}}:</p>
                <button *ngIf="i >= 2" color="secondary" mat-mini-fab style="width: 40px;" (click)="removeFamilymember(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Voornaam</mat-label>
                  <input matInput required name="firstname {{i}}" [(ngModel)]="member.firstname">
                </mat-form-field>
        
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Naam</mat-label>
                  <input matInput required name="lastname {{i}}" [(ngModel)]="member.lastname">
                </mat-form-field>

                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Geboortedatum</mat-label>
                  <input matInput required name="birthdate {{i}}" [(ngModel)]="member.birthdate">
                </mat-form-field>
              </div>

              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Rijksregisternummer</mat-label>
                  <input matInput required name="registernumber {{i}}" [(ngModel)]="member.registernumber">
                </mat-form-field>
        
                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>E-mailadres</mat-label>
                  <input matInput required name="emailaddress {{i}}" [(ngModel)]="member.emailaddress">
                </mat-form-field>

                <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                  <mat-label>Geslacht</mat-label>
                  <mat-select name="gender {{i}}" [(ngModel)]="member.gender" required>
                    <mat-option value="Man">
                      Man
                    </mat-option>
                    <mat-option value="Vrouw">
                      Vrouw
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <br/>
           </section>
           <div fxFill fxLayout="row" fxLayoutAlign="space-around center" *ngIf="subscription.formulaPadel == '6'">
            <button mat-raised-button color="primary" (click)="addFamilymember()">+ Nog een familielid toevoegen</button>
           </div>
          </section>

          <section *ngIf="this.type == 'Combi'">
            <p>Zomer lidmaatschap Tennis (1 april – 31 oktober, afhankelijk van het weer) <br/> + Padel lidmaatschap</p>
    
            <mat-radio-group required fxLayout="column" fxLayoutGap="15px" name="formulaTennis" [(ngModel)]="subscription.formulaCombi">
              <mat-radio-button *ngFor="let option of this.combiProducts[0].sizes; let i = index" value="{{i + 1}}" (change)="radioChangeCombi($event, option)">{{ option.name}} <span *ngIf="i == 5"><br fxHide.gt-sm/> (voor gezinnen vanaf 3 of meer personen) <br fxHide.gt-sm/></span> - €{{ option.newPrice }}</mat-radio-button>
             </mat-radio-group> 
     
             <br/>
     
             <section *ngFor="let member of this.subscription.familymembers; let i = index">
              <div fxLayout="column" *ngIf="subscription.formulaCombi == '6'" style="border-bottom: 2px solid #eb5f44;">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <p>Familielid {{i+2}}:</p>
                  <button *ngIf="i >= 2" color="secondary" mat-mini-fab style="width: 40px;" (click)="removeFamilymember(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                  <mat-form-field fxFill fxFlex="100" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Voornaam</mat-label>
                    <input matInput required name="firstname {{i}}" [(ngModel)]="member.firstname">
                  </mat-form-field>
          
                  <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Naam</mat-label>
                    <input matInput required name="lastname {{i}}" [(ngModel)]="member.lastname">
                  </mat-form-field>

                  <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Geboortedatum</mat-label>
                    <input matInput required name="birthdate {{i}}" [(ngModel)]="member.birthdate">
                  </mat-form-field>
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap.gt-sm="20px">
                  <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Rijksregisternummer</mat-label>
                    <input matInput required name="registernumber {{i}}" [(ngModel)]="member.registernumber">
                  </mat-form-field>
          
                  <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>E-mailadres</mat-label>
                    <input matInput required name="emailaddress {{i}}" [(ngModel)]="member.emailaddress">
                  </mat-form-field>

                  <mat-form-field fxFill fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Geslacht</mat-label>
                    <mat-select name="gender {{i}}" [(ngModel)]="member.gender" required>
                      <mat-option value="Man">
                        Man
                      </mat-option>
                      <mat-option value="Vrouw">
                        Vrouw
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <br/>
             </section>

             <div fxFill fxLayout="row" fxLayoutAlign="space-around center" *ngIf="subscription.formulaCombi == '6'">
              <button mat-raised-button color="primary" (click)="addFamilymember()">+ Nog een familielid toevoegen</button>
             </div>
             
          </section>
  
        
  
        <p>Totale prijs lidmaatschap:</p>
        <p><strong>€{{this.total | number:'1.2-2' }}</strong></p>
  
        <hr/>

        <mat-checkbox name="invoice" [(ngModel)]="subscription.invoice">Ik wens een factuur te ontvangen <br fxHide.gt-sm/> (enkel voor ondernemingen)</mat-checkbox>

        <br/>
  
        <mat-checkbox required name="termsandconditions" [(ngModel)]="subscription.termsandconditions">Ik ga akkoord dat Tennisclub Katteberg <br fxHide.gt-sm/> mijn gegevens enkel verwerkt voor de <br fxHide.gt-sm/> registratie van en de communicatie <br fxHide.gt-sm/> over mijn lidmaatschap</mat-checkbox>
        <mat-error *ngIf="!this.subscription.termsandconditions && this.showErrors">Gelieve akkoord te gaan</mat-error>

          
        <br/>
        <br/>
  
        <div fxFlex="100" fxLayoutAlign="center center">
          <button [disabled]="subscriptionForm.invalid || this.total == 0" type="sumbit" (click)="onSubmit()" color="primary" mat-raised-button>Lidmaatschap Afrekenen</button>
        </div>
  
        <br/>
  
        <p>Voor meer informatie kan je contact opnemen met Karel Demuynck – Sportief manager
          telefonisch op 0488 / 38 63 07 of via mail op karel@tckatteberg.be</p>
      </form>
    </div>
  
  </div>

  <div class="footer" fxLayout="column" fxLayoutAlign="center center">
    <p>© Copyright TC Katteberg. <br fxHide.gt-sm/> Alle Rechten Voorbehouden</p>
    <a>Powered by</a>
    <span fxLayout="row">
      
      <a href="https://lms-it.be" target="_blank">LMS-IT Solutions</a>
      <img src="../../assets/img/logo-lms-circles.svg" height="30" width="30" />
    </span>
    
    <br/>
  </div>