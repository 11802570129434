import { Member } from "./member.model";

export class Subscription {
    public firstname: string;
    public lastname: string;
    public registernumber: string;
    public emailaddress: string;
    public phonenumber: string;
    public street: string;
    public housenumber: string;
    public city: string;
    public zipcode: string;
    public birthdate: string;
    public gender: string;
    public formulaTennis: any;
    public formulaPadel: any;
    public formulaCombi: any;
    public formula: any = "";
    public commentTennis: any;
    public commentPadel: any;
    public commentCombi: any;
    public comment: any;
    public termsandconditions: boolean = false;
    public invoice: boolean = false;
    public familymembers: Member[] = [];
}