<div class="container" fxLayout="column" fxLayoutAlign="start center">

    <div class="background" fxLayoutAlign="center center">
      <!-- <h1>Lidmaatschap TC Katteberg</h1> -->
      <img src="../../assets/img/logo_white.svg"/>
    </div>
  
    <div class="info" fxLayout="column" fxLayoutAlign="center center">
      <button mat-fab color="primary"><mat-icon>check</mat-icon></button>
      <span *ngIf="this.ready" fxLayout="column" fxLayoutAlign="center center">
        <h2>Bedankt voor je inschrijving {{order.firstname}}!</h2>
        <p>Je hebt je ingeschreven voor: <strong>{{ order.products[0].name }}</strong></p>
        <br/>
        <button color="primary" (click)="back()" mat-raised-button>Terug naar de website</button>
      </span>
     
    </div>
    
  
</div>

<div class="footer" fxLayout="column" fxLayoutAlign="center center">
  <p>© Copyright TC Katteberg. <br fxHide.gt-sm/> Alle Rechten Voorbehouden</p>
  <a>Powered by</a>
  <span fxLayout="row">
    
    <a href="https://lms-it.be" target="_blank">LMS-IT Solutions</a>
    <img src="../../assets/img/logo-lms-circles.svg" height="30" width="30" />
  </span>
  
  <br/>
</div>
