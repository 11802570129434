import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from "@angular/forms";
import { SubscribeComponent } from './subscribe/subscribe.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ConfirmComponent } from './confirm/confirm.component';
import { ErrorComponent } from './error/error.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppInterceptor } from "./shared/app-interceptor";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SubscribeComponent,
    ConfirmComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
