import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../shared/models/order';
import { PaymentService } from '../shared/services/payment.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public order: Order = new Order();
  public ready: boolean = false;

  constructor(private paymentService: PaymentService, private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params["id"]) {
        this.paymentService.getOrderById(params["id"]).subscribe(data => {
          console.log(data);
          this.order = data;
          this.ready = true;
        });
      }
    });
  }

  public back() {
    window.location.href="https://www.tckatteberg.be";
  }

}
