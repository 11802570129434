import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Member } from '../shared/models/member.model';
import { Order } from '../shared/models/order';
import { Payment } from '../shared/models/payment';
import { Product } from '../shared/models/product';
import { Size } from '../shared/models/size';
import { Subscription } from '../shared/models/subscription.model';
import { PaymentService } from '../shared/services/payment.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  public subscription: Subscription = new Subscription();
  public total: number = 0;
  public type: string = "";

  public tennisProducts: Product[] = [];
  public padelProducts: Product[] = [];
  public combiProducts: Product[] = [];
  public isActive: boolean = false;

  public showErrors: boolean = false;

  public chosenProduct = new Product();

  constructor(private paymentService: PaymentService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.subscription.familymembers.push(new Member());
    this.subscription.familymembers.push(new Member());

    this.fillTennisProducts();
    this.fillPadelProducts();
    this.fillCombiProducts();
  }

  public onSubmit() {
    if (this.total != 0) {
      //this.submitOrder(); //put this instead of below code to not check for existing members
      if (this.type == "Tennis") { //put this to check on emailaddresses for existing members for padel
        this.submitOrder();
      } else {
        this.paymentService.checkTennisEmail(this.subscription.emailaddress).subscribe(data => {
          if (data) {
            this.submitOrder();
          } else {
            this._snackBar.open(
              "We hebben je niet teruggevonden in de ledenlijst, indien je wel lid bent neem dan aub contact met ons op.",
              "×",
              { panelClass: ['error-popup'], verticalPosition: "top", duration: 6000 }
            );
          }
        });
      }

      
    } else {
      this._snackBar.open(
        "Formulier niet correct ingevuld.",
        "×",
        { panelClass: ['error-popup'], verticalPosition: "top", duration: 3000 }
      );
    }
    
  }


  public radioChangeTennis(event: any, option: Size) {
    this.chosenProduct.id = "DBF6F2A3-BBBF-4BCB-ADC9-659AF3C42629";
    this.chosenProduct.articleId = option.id;
    this.chosenProduct.name = "Tennis " + option.name;
    this.chosenProduct.sizes = [];
    this.chosenProduct.sizes.push(option);
    this.total = option.newPrice;
  }

  public changeFormulaType() {
    this.total = 0;
    this.subscription.formulaCombi = "";
    this.subscription.formulaPadel = "";
    this.subscription.formulaTennis = "";
  }

  public radioChangePadel(event: any, option: Size) {
    this.chosenProduct.id = "CE39287E-1151-4534-B03A-20A26387739E";
    this.chosenProduct.articleId = option.id;
    this.chosenProduct.name = "Padel " + option.name
    this.chosenProduct.sizes = [];
    this.chosenProduct.sizes.push(option);
    this.total = option.newPrice;
  }

  public radioChangeCombi(event: any, option: Size) {
    this.chosenProduct.id = "460147C7-C410-4782-B94D-FF3F77E63C4D";
    this.chosenProduct.articleId = option.id;
    this.chosenProduct.name = "Combi " + option.name
    this.chosenProduct.sizes = [];
    this.chosenProduct.sizes.push(option);
    this.total = option.newPrice;
  }

  public addFamilymember() {
    this.subscription.familymembers.push(new Member());
  }

  public removeFamilymember(index: number) {
    if (this.subscription.familymembers.length > 2) {
      this.subscription.familymembers.splice(index, 1);
    }
  }

  public submitOrder() {
    this.fillOrderDescription();
    let newPayment = new Payment();
    newPayment.amount.currency = "EUR";
    newPayment.amount.value = this.total + "";

    let newOrder = new Order();
    newOrder.total = this.total;
    newOrder.products.push(this.chosenProduct);
    newOrder.address = this.subscription.street + " " + this.subscription.housenumber;
    newOrder.city = this.subscription.city;
    newOrder.email = this.subscription.emailaddress;
    newOrder.firstname = this.subscription.firstname;
    newOrder.lastname = this.subscription.lastname;
    newOrder.phone = this.subscription.phonenumber;
    newOrder.zipcode = this.subscription.zipcode;

    console.log(newOrder);

    this.paymentService.postNewOrder(newOrder, newPayment).subscribe((data) => {
      window.location.href = data.checkoutUrl;
    });
  }

  public fillOrderDescription() {
    this.chosenProduct.description = "";
    this.chosenProduct.description += (this.subscription.firstname + ";" + this.subscription.lastname + ";" + 
      this.subscription.emailaddress + ";" + 
      this.subscription.phonenumber + ";" + 
      this.subscription.street + ";" + 
      this.subscription.housenumber + ";" + 
      this.subscription.city + ";" + 
      this.subscription.zipcode + ";" + 
      this.subscription.registernumber + ";" + 
      this.subscription.birthdate + ";" + 
      this.subscription.gender) + ";";

    if (this.subscription.invoice) {
      this.chosenProduct.description += "ja:";
    } else {
      this.chosenProduct.description += "nee:";
    }

    if (this.subscription.formulaTennis == "6" || this.subscription.formulaPadel == "6" || this.subscription.formulaCombi == "6") {
      this.subscription.familymembers.forEach(member => {
        this.chosenProduct.description += ((member.firstname + ";" + member.lastname + ";" + member.emailaddress + ";" + member.registernumber + ";" + member.birthdate + ";" + member.gender) + ":");
      });
    }
  }

  public fillTennisProducts() {
    this.tennisProducts = [
      {
        id: "DBF6F2A3-BBBF-4BCB-ADC9-659AF3C42629",
        name: "Tennis",
        newPrice: 0,
        description: "",
        chosenSize: "",
        sizes: [
          {
            id: "647C9743-BD75-4301-6373-08D9D739F74F",
            name: "< 6 jaar",
            newPrice: 30,
            count: 1
          },
          {
            id: "7D2196D1-B7A3-471C-6374-08D9D739F74F",
            name: "7 - 11 jaar",
            newPrice: 55,
            count: 1
          },
          {
            id: "2EAF9FA1-DB34-4191-6375-08D9D739F74F",
            name: "12 - 18 jaar",
            newPrice: 85,
            count: 1
          },
          {
            id: "326B49C5-54B1-4231-6376-08D9D739F74F",
            name: "19 - 25 jaar",
            newPrice: 110,
            count: 1
          },
          {
            id: "DE0BB5B7-055C-46EB-6377-08D9D739F74F",
            name: "25+ jaar",
            newPrice: 155,
            count: 1
          },
          {
            id: "C49DAC1E-A8FC-4A8E-6378-08D9D739F74F",
            name: "Familieabonnement",
            newPrice: 375,
            count: 1
          },
        ]
      }
    ];
  }

  public fillPadelProducts() {
    this.padelProducts = [
      {
        id: "CE39287E-1151-4534-B03A-20A26387739E",
        name: "Padel",
        newPrice: 0,
        description: "",
        chosenSize: "",
        sizes: [
          {
            id: "63B27F45-3EC1-435C-6379-08D9D739F74F",
            name: "< 6 jaar",
            newPrice: 50,
            count: 1
          },
          {
            id: "86B3CDBA-9335-46E7-637A-08D9D739F74F",
            name: "7 - 11 jaar",
            newPrice: 75,
            count: 1
          },
          {
            id: "2CCBAB9E-9AF6-4E6F-637B-08D9D739F74F",
            name: "12 - 18 jaar",
            newPrice: 105,
            count: 1
          },
          {
            id: "DA3616C0-AB0E-49FE-637C-08D9D739F74F",
            name: "19 - 25 jaar",
            newPrice: 150,
            count: 1
          },
          {
            id: "135036CC-0754-4596-637D-08D9D739F74F",
            name: "25+ jaar",
            newPrice: 205,
            count: 1
          },
          {
            id: "33CEA6E1-1F07-4D02-637E-08D9D739F74F",
            name: "Familieabonnement",
            newPrice: 460,
            count: 1
          },
        ]
      }
    ];
  }

  public fillCombiProducts() {
    this.combiProducts = [
      {
        id: "460147C7-C410-4782-B94D-FF3F77E63C4D",
        name: "Combi",
        newPrice: 0,
        description: "",
        chosenSize: "",
        sizes: [
          {
            id: "BE675494-9363-4FEB-637F-08D9D739F74F",
            name: "< 6 jaar",
            newPrice: 70,
            count: 1
          },
          {
            id: "28EEFE34-24A8-4BEE-6380-08D9D739F74F",
            name: "7 - 11 jaar",
            newPrice: 110,
            count: 1
          },
          {
            id: "D96524C4-7EBD-4291-6381-08D9D739F74F",
            name: "12 - 18 jaar",
            newPrice: 175,
            count: 1
          },
          {
            id: "8C4B9630-1615-4116-6382-08D9D739F74F",
            name: "19 - 25 jaar",
            newPrice: 230,
            count: 1
          },
          {
            id: "7F2A11BD-0E39-4F37-6383-08D9D739F74F",
            name: "25+ jaar",
            newPrice: 315,
            count: 1
          },
          {
            id: "20B863EA-C5DF-43C8-6384-08D9D739F74F",
            name: "Familieabonnement",
            newPrice: 740,
            count: 1
          },
        ]
      }
    ];
  }

}
