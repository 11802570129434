import { Order } from "./order";
import { Payment } from "./payment";

export class Paymentobject {
  public order: Order;
  public payment: Payment;

  constructor(order: Order, payment: Payment) {
    this.order = order;
    this.payment = payment;
  }
}
