import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, of, throwError } from "rxjs";

import { Product } from "../models/product";
import { catchError } from "rxjs/operators";
import { Payment } from "../models/payment";
import { Order } from "../models/order";
import { Paymentobject } from "../models/paymentobject";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private molliePaymentUrl = "https://api.mollie.com/v2/payments";
  private shopId: string = "a55b2d26-44f9-46e7-a7e8-df2e24aeb5f0";
  private paymentControllerUrl = environment.shopApi + this.shopId + "/Orders/";
  private tennisControllerUrl = environment.tennisApi;

  constructor(private http: HttpClient) {}

  public checkTennisEmail(email:string): Observable<any> {
    return this.http
    .get<any>(this.tennisControllerUrl + email, httpOptions)
    .pipe(catchError(this.handleError));
  }

  public getNewBpostOrder(): Observable<Order> {
    return this.http
      .get<Order>(this.paymentControllerUrl, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public postPaymentToMollie(payment: Payment): Observable<Payment> {
    return this.http
      .post<Payment>(this.molliePaymentUrl, payment, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getOrderById(orderId: string): Observable<Order> {
    return this.http
      .get<Order>(this.paymentControllerUrl + orderId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public postNewOrder(order: Order, payment: Payment): Observable<any> {
    let newPaymentobject = new Paymentobject(order, payment);
    console.log(JSON.stringify(newPaymentobject));
    return this.http
      .post<any>(this.paymentControllerUrl, newPaymentobject, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Fout:", error.error.message);
    } else {
      console.error(
        `Backend returned code Deze code voor handling ${error.status}` +
          ` body was: ${error.error}`
      );
    }

    return throwError("Er is iets fout gegaan");
  }
}
