import { Product } from "./product";

export class Order {
  public id: string;
  public orderNumber: string;
  public date: string;
  public paymentStatus: string;
  public processStatus: string;
  public products: Product[] = [];
  public total: number;
  public deliveryMethod: string;
  public firstname: string;
  public lastname: string;
  public email: string;
  public phone: string;
  public country: string;
  public city: string;
  public province: string;
  public zipcode: string;
  public address: string;
  public company: string;
  public automaticShipping: boolean;

  constructor() {}
}
